import { imageBasePath } from "lib/utils/imageUtils";

export const statsData = [
  {
    id: 1,
    img: `${imageBasePath}/assets/images/new-landing-page/university_icon.svg`,
    text: (
      <span>
        Universities <br className="inline md:hidden" />
        Supported
      </span>
    ),
    end: 231,
    statSuffix: "+",
    showBorder: true,
  },
  {
    id: 2,
    img: `${imageBasePath}/assets/images/new-landing-page/courses_icon.svg`,
    text: (
      <span>
        Courses <br className="inline md:hidden" />
        Supported
      </span>
    ),
    end: 2300,
    statSuffix: "+",
    showBorder: true,
  },
  {
    id: 3,
    img: `${imageBasePath}/assets/images/new-landing-page/loan_icon.svg`,
    text: (
      <span>
        Loans <br className="inline md:hidden" />
        Funded
      </span>
    ),
    end: 175,
    statSuffix: "Mn",
    statPrefix: "$",
    showBorder: false,
  },
];

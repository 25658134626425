import { imageBasePath } from "lib/utils/imageUtils";

export const leapAdvantageData = [
  {
    id: 1,
    webImg: `${imageBasePath}/assets/images/new-landing-page/save_dollars.svg`,
    mobileImg: `${imageBasePath}/assets/images/new-landing-page/save_dollars_mobile.svg`,
    title: "Designed to save dollars for you",
    subTitle: (
      <span>
        Minimal fees, zero hidden charges
        <br />
        We believe in your future potential
      </span>
    ),
    imgAlt: "Save Dollars",
    webImgWidth: 335,
    webImgHeight: 300,
    mobileImgWidth: 91,
    mobileImgHeight: 91,
  },
  {
    id: 2,
    webImg: `${imageBasePath}/assets/images/new-landing-page/online_process.svg`,
    mobileImg: `${imageBasePath}/assets/images/new-landing-page/online_process_mobile.svg`,
    title: "Quick Online Process",
    subTitle: (
      <span>
        No more boring paper work
        <br />
        Complete the process at your comfort
      </span>
    ),
    imgAlt: "Online Process",
    webImgWidth: 335,
    webImgHeight: 300,
    mobileImgWidth: 91,
    mobileImgHeight: 91,
  },
  {
    id: 3,
    webImg: `${imageBasePath}/assets/images/new-landing-page/journey.svg`,
    mobileImg: `${imageBasePath}/assets/images/new-landing-page/journey_mobile.svg`,
    title: "We support your journey",
    subTitle: (
      <span>
        Student community, counselling and more
        <br /> Value at each step in your journey
      </span>
    ),
    imgAlt: "Journey",
    webImgWidth: 335,
    webImgHeight: 300,
    mobileImgWidth: 91,
    mobileImgHeight: 91,
  },
];

import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import { imageBasePath } from "lib/utils/imageUtils";
import Image from "next/image";
import { localStorageUtils } from "lib/utils/storageUtils";
import useLogin from "lib/hooks/useLogin";
import { useRouter } from "next/router";
import { pageRoutes } from "constant/routes";
import { handleRedirectToHelpCenter } from "components/HelpCenter/helper";
import ProfileMenuItem from "components/Layout/ProfileMenuItem";

const ProfileMenu = ({
  userPhoneNo,
  isDisbursementFlow,
  showHelpCenter = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { logout } = useLogin();
  const router = useRouter();

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleNavigation = () => {
    const slug = localStorageUtils?.getItem("applicationSlug");
    const path = isDisbursementFlow
      ? `/disbursements/${slug}`
      : pageRoutes.INSCHOOL_DASHBOARD;
    router.push(path);
  };

  return (
    <div className="inline-block mr-2 md:mr-4">
      <button onClick={toggleMenu}>
        <div className="inline-block rounded-full border-2 align-middle mr-2 -mt-1">
          <Avatar
            sx={{
              width: "26px",
              height: "26px",
            }}
            alt={userPhoneNo}
            src={`${imageBasePath}/assets/icons/icon-user.svg`}
          >
            {userPhoneNo}
          </Avatar>
        </div>
        <span className="text-sm font-semibold text-gray-700">
          <span className="md:inline-block hidden">{userPhoneNo}</span>
        </span>
        <span className="ml-2">
          <Image
            className="inline-block"
            src={`${imageBasePath}/assets/icons/bottom-carat.svg`}
            width={10}
            height={6}
            alt="Menu open icon"
          />
        </span>
      </button>
      {isOpen && (
        <div className="block bg-white py-1 fixed w-auto rounded-sm mt-2 -ml-24 md:ml-0 shadow-profileMenu">
          <div className="text-base leading-loose">
            <ProfileMenuItem
              iconUrl={`${imageBasePath}/assets/icons/applications-icon.svg`}
              label={isDisbursementFlow ? "Dashboard" : "Applications"}
              onClick={handleNavigation}
            />
            {showHelpCenter && (
              <ProfileMenuItem
                iconUrl={`${imageBasePath}/assets/icons/help-center-icon.svg`}
                label="Help Center"
                onClick={handleRedirectToHelpCenter}
              />
            )}
            <ProfileMenuItem
              iconUrl={`${imageBasePath}/assets/icons/logout-icon.svg`}
              label="Logout"
              onClick={() => logout()}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;

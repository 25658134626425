import { useState } from "react";
import { flowTypes } from "./LoginConstants";
import dynamic from "next/dynamic";
const Login = dynamic(() => import("../Login"));

const withLogin = (Component) => (props) => {
  const [showLoginView, setShowLoginView] = useState(false);
  const [canClose, setCanClose] = useState(true);
  const [preFilledPhone, setPrefilledPhone] = useState("");
  const [flowType, setFlowType] = useState("");
  const [canChangeCountryCode, setCanChangeCountryCode] = useState(true);

  const initLogin = (
    canClose = true,
    phone = "",
    flowType = flowTypes.INSCHOOL
  ) => {
    setShowLoginView(true);
    setCanClose(canClose);
    setPrefilledPhone(phone);
    setFlowType(flowType);
    flowType === flowTypes.BANK && setCanChangeCountryCode(false);
  };

  return (
    <>
      <Component {...props} initLogin={initLogin} />
      {showLoginView && (
        <Login
          canClose={canClose}
          handleClose={() => {
            setShowLoginView(false);
            setCanChangeCountryCode(true);
          }}
          isModalOpen={showLoginView}
          preFilledPhone={preFilledPhone}
          flowType={flowType}
          resetPreFilledPhone={() => setPrefilledPhone("")}
          canChangeCountryCode={canChangeCountryCode}
        />
      )}
    </>
  );
};

export default withLogin;

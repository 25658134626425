import { useState, useEffect } from "react";

export default function withWindowDimensions(WrappedComponent) {
  return (props) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
      const updateWindowDimensions = () => {
        setDimensions({ width: window.innerWidth, height: window.innerHeight });
      };

      updateWindowDimensions();
      window.addEventListener("resize", updateWindowDimensions);

      return function cleanup() {
        window.removeEventListener("resize", updateWindowDimensions);
      };
    }, []);

    return (
      <WrappedComponent
        {...props}
        windowWidth={dimensions.width}
        windowHeight={dimensions.height}
        isMobileSized={dimensions.width > 0 && dimensions.width < 768}
      />
    );
  };
}

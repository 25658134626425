import { ProductTypes } from "lib/utils/constants";

export const educationLoanData = {
  id: 1,
  title: "Education Loan Simplified",
  image:
    "https://ik.imagekit.io/onsnhxjshmp/Leap_Finance/education_loan_azl4sAB-t.png",
  product: ProductTypes.INSCHOOL,
  webImageWidth: 624,
  webImageHeight: 569,
  mobileImageWidth: 358,
  mobileImageHeight: 326.45,
};

import Image from "next/image";

const ProfileMenuItem = ({ iconUrl, onClick, label }) => (
  <button
    className="hover:bg-[#EEEEF2] pl-3 pr-8 md:pr-12 cursor-pointer flex items-center gap-2 w-full"
    onClick={onClick}
  >
    <Image src={iconUrl} width={16} height={16} alt={`${label} icon`} />
    <span>{label}</span>
  </button>
);

export default ProfileMenuItem;

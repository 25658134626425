import withWindowDimensions from "components/shared/withWindowDimensions";
import { pageRoutes } from "constant/routes";
import { imageBasePath } from "lib/utils/imageUtils";
import Image from "next/image";
import { useRouter } from "next/router";

const ReferralBanner = ({ isMobileSized }) => {
  const router = useRouter();

  const backgroundStyle = {
    backgroundImage:
      "url(/assets/images/referral/bg-1.png), url(/assets/images/referral/bg-2.png), url(/assets/images/referral/bg-3.png)",
    backgroundPosition: "15%, 17%, right",
    backgroundSize: "contain, contain, contain",
    backgroundRepeat: "no-repeat, no-repeat, no-repeat",
  };

  return (
    <div className="bg-[linear-gradient(91.85deg,_#241583_-1.72%,_#892AD3_103.53%)]">
      <div
        className="text-xs md:text-base flex w-full text-center text-white px-4 md:px-0 font-semibold cursor-pointer items-center justify-center gap-6"
        style={!isMobileSized ? backgroundStyle : {}}
      >
        <div className="bg-[#FFFFFF33] px-2 py-1 my-2 flex items-center mt-[5px] rounded-lg">
          <Image
            src={`${imageBasePath}/assets/images/referral/sparkling-stars.svg`}
            width={24}
            height={24}
            alt="sparkling"
            className="mr-2"
          />
          <span>
            Refer students, earn rewards, and celebrate success together!
          </span>
          <button
            type="button"
            className="ml-3 md:ml-6 bg-white flex items-center px-2 py-1.5 rounded w-[150px] whitespace-nowrap justify-center"
            onClick={() => router.push(pageRoutes.REFERRAL)}
          >
            <span className="text-[#000000]">Refer A Friend</span>
            <Image
              src={`${imageBasePath}/assets/images/referral/next-icon.svg`}
              width={16}
              height={16}
              alt="sparkling"
              className="hidden md:block"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default withWindowDimensions(ReferralBanner);

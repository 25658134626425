import { imageBasePath } from "lib/utils/imageUtils";

export const testimonialData = [
  {
    id: 1,
    name: "Abhinav",
    city: "Kanpur",
    story: `“I am impressed by the service Leap Finance provides at every stage of the process. It is quick and seamless. Really doubt anyone could make the loan look this cool!”`,
    college: "Columbia University",
    collegeCity: "New York",
    image:
      "https://ik.imagekit.io/onsnhxjshmp/Leap_Finance/Student-testimonials/abhinav_vWfN9nRDli.jpg",
    mobileImage: `${imageBasePath}/assets/images/new-landing-page/testimonials/abhinav-mobile.jpg`,
  },
  {
    id: 2,
    name: "Abhishek",
    city: "Bangalore",
    story: `Leap gave me a Hassle free and customer satisfaction service throughout my loan processing. I was pleased to receive loan sanction from Leap without any collateral. Happy to know someone else has my back other than my parents`,
    college: "Stevens Institute of Technology",
    collegeCity: "Hoboken",
    image:
      "https://ik.imagekit.io/onsnhxjshmp/Leap_Finance/Student-testimonials/abhishek_N-n8Ju6wa.jpg",
    mobileImage: `${imageBasePath}/assets/images/new-landing-page/testimonials/abhishek-mobile.jpg`,
  },
  {
    id: 3,
    name: "Sai",
    city: "Hyderabad",
    story: `Overall, experience and process with Leap were smooth and quick.Factors such as No Collateral, Less ROI, Less Documentation, Complete Online Process, and No hidden charges attracted me the most. Would highly recommend Leap to others`,
    college: "Georgia State University",
    collegeCity: "Atlanta",
    image:
      "https://ik.imagekit.io/onsnhxjshmp/Leap_Finance/Student-testimonials/saipreeth_XS9IK-HBn.jpg",
    mobileImage: `${imageBasePath}/assets/images/new-landing-page/testimonials/saipreeth-mobile.jpg`,
  },
  {
    id: 4,
    name: "Shreyas",
    city: "Chennai",
    story: `Leap's application process is quick and online without any hassles. I was provided with a competitive loan offer within a few minutes. Leap also organized multiple sessions with their team who provided guidance on student finances and the visa application process. It was truly a one-stop-shop experience for me.`,
    college: "Georgia Institute of Technology",
    collegeCity: "Atlanta",
    image:
      "https://ik.imagekit.io/onsnhxjshmp/Leap_Finance/Student-testimonials/shreyas_CW6RQLexHB.jpg",
    mobileImage: `${imageBasePath}/assets/images/new-landing-page/testimonials/shreyas-mobile.jpg`,
  },
];

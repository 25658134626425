import DOMPurify from "isomorphic-dompurify";

export const OrganizationSchema = () => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(`{
    "@context": "http://www.schema.org",
    "@type": "Organization",
    "name": "Leap Finance",
    "url": "https://leapfinance.com",
    "logo": "http://cdn.mcauto-images-production.sendgrid.net/34a5d9ee598a3b60/183d91ed-64a7-4246-adda-f8939b934929/1920x1200.png",
    "description": "Leap finance is a one-stop destination for Indian students to fund their overseas education. Low-Interest Rate ✓No Collateral ✓100% loan coverage.",
    "sameAs" : [
      "https://www.facebook.com/leapfinanceofficial",
      "https://twitter.com/leapfinancein",
      "https://www.linkedin.com/company/leapfinance/about/",
      "https://www.instagram.com/leap_finance/"
    ],
    "address": {
       "@type": "PostalAddress",
       "addressLocality": "Bangalore",
       "addressRegion": "Karnataka",
       "postalCode": "560102",
       "addressCountry": "India"
    }
  }`),
      }}
    />
  );
};

export const HomeFaqSchema = () => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(`{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Who can apply for Leap Finance’s loans?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Any student aiming to pursue master's or postgraduate programs in STEM courses at US universities is eligible to receive financial support from Leap Finance."
              }
            },
            {
              "@type": "Question",
              "name": "Who is a co-signer and do I need one?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "A co-signer (or co-borrower) takes the loan jointly with you. The co-signer could be your parent or any other family member who commits to paying the balance of the loan in the event that you cannot."
              }
            },
            {
              "@type": "Question",
              "name": "When do I get my sanction letter?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "You will get a provisional offer online within ten minutes. Once you submit the supporting documents, we will issue an official sanction letter within 72 hours"
              }
            },
            {
              "@type": "Question",
              "name": "How will I receive my loan funds?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Your tuition fee will be transferred directly to the university through Flywire. Living expenses will be deposited into your US bank account."
              }
            }
          ]
        }`),
      }}
    />
  );
};

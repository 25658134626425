export function isEmpty(str) {
  return !str || 0 === str.length;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const underlineLastNWords = (sentence, n) => {
  if (!sentence) return null;
  
  const words = sentence?.split(" ");
  const underlinedWords = words?.map((word, index) => {
    if (index >= words?.length - n) {
      return <u key={index}>{word} </u>;
    }
    return <span key={index}>{word} </span>;
  });

  return <div>{underlinedWords}</div>;
};


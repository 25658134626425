export const flowTypes = {
  INSCHOOL: "education",
  BANK: "bank",
  REFI: "refi",
  WALLET: "wallet",
  INSCHOOL_BANK_UPDATE: "inschool-bank-update",
};

export const loginContentBasedOnFlowType = {
  [flowTypes.INSCHOOL]: {
    title:
      "Your interest loan offer is just <br /><span class='text-primary'>10 minutes away</span>",
    stats: [
      "<span class='text-blue-950 font-bold'>231+</span> colleges supported",
      "<span class='text-blue-950 font-bold'>$175 Mn</span> loans disbursed",
      "<span class='text-blue-950 font-bold'>2300+</span> courses supported",
      "Sanction letter in <span class='text-blue-950 font-bold'>3 days</span>",
    ],
  },
  [flowTypes.BANK]: {
    title:
      "Your credit card* is just  <br /><span class='text-primary'>5 minutes away</span>",
    stats: [
      "Credit Limit upto <span class='text-blue-950 font-bold'>$1500</span>",
      "No credit history needed",
      "<span class='text-blue-950 font-bold'>Minimal</span> forex charges",
      "Open Account in <span class='text-blue-950 font-bold'>5 mins</span>",
    ],
  },
  [flowTypes.REFI]: {
    title:
      "Your low interest loan offer is just <br /><span class='text-primary'>5 minutes away</span>",
    stats: [
      "<span class='text-blue-950 font-bold'>F1, OPT, H1b</span> supported",
      "<span class='text-blue-950 font-bold'>Fixed</span> interest rate",
      "<span class='text-blue-950 font-bold'>Collateral Free</span>",
      "Sanction letter in <span class='text-blue-950 font-bold'>3 days</span>",
    ],
  },
  [flowTypes.WALLET]: {
    title:
      "Your low interest loan offer is just <br /><span class='text-primary'>5 minutes away</span>",
    stats: [
      "<span class='text-blue-950 font-bold'>F1, OPT, H1b</span> supported",
      "<span class='text-blue-950 font-bold'>Fixed</span> interest rate",
      "<span class='text-blue-950 font-bold'>Collateral Free</span>",
      "Sanction letter in <span class='text-blue-950 font-bold'>3 days</span>",
    ],
  },
};

export const loginFlowRedirectionMapping = {
  NEW_INSCHOOL_USER_ROUTE: `/applications/new?new_user=true&user_context=${flowTypes.INSCHOOL}`,
  NEW_BANK_USER_ROUTE: `/applications?new_user=true&user_context=${flowTypes.BANK}`,
  NEW_US_USER_ROUTE: "/choose-citizen?new_user=true",
  EXISTING_INSCHOOL_USER_ROUTE: `/applications?sign_in=true&user_context=${flowTypes.INSCHOOL}`,
  EXISTING_BANK_USER_ROUTE: `/applications?sign_in=true&user_context=${flowTypes.BANK}`,
  EXISTING_US_USER_ROUTE: "/choose-citizen",
  INSCHOOL_DASHBOARD_ROUTE: "/applications",
};

export const activeViewTypes = {
  OTP: "OTP",
  EMAIL: "EMAIL",
  PHONE: "PHONE",
};
